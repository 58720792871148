import "./App.css";
import React, { createContext, useContext, useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import API from "./service/Api";
import useScript from "./utils/ScriptLoder";
import TagManager from "react-gtm-module";
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import HowItWorks from "./pages/HowItWorks";
import ContactUs from "./pages/ContactUs";
import PrivcyPolicy from "./pages/PrivacyPolicy";
import Terms from "./pages/terms";
import EConsent from "./pages/EConsent";
import Disclaimer from "./pages/Disclaimer";
import DoNotSell from "./pages/DoNotSell";
import Unsubscribe from "./pages/Unsubscribe";
import RateAndFees from "./pages/RateAndFees";
import FaqPage from "./pages/FaqPage";
import MainForm from "./component/form/MainForm";

const defaultData = {
  website_id: "",
  sub: "",
  domain_id: "",
  domain_name: "",
  server_id: "",
  logo_img: "logo.svg",
  login_img: "",
  favicon_img: "favicon.svg",
  min_amount: "1000",
  max_amount: "15000",
  banner_img: "banner.png",
  section1_img: "img1.png",
  section2_img: "",
  section3_img: "",
  section4_img: "plus.svg,minus.svg",
  primary_color: "#00A6BA",
  secondary_color: "#0085FF",
  form_id: "1",
  form_url: "https://useasycash.com/formcdn/version1.2.1/?c=Home",
  ga_script: "",
  push_script: "",
  ola_script: "",
  customization: {
    headerSection: {
      section: "Header",
      content: {},
      style: {
        backgroundColor: "#ffffff",
        gradColor1: "#E8FDFF",
        gradColor2: "#FAF6FF",
        linkColor: "#191C1A",
        linkHoverColor: "#00A6BA",
      },
    },

    bannerSection: {
      section: "Banner",
      content: {
        headingText: "Request a loan for",
        subheadingText: "ALL CREDIT TYPE WELCOME",
        ctaText: "Request Now",
        ctaSubText: "It's free",
        label1: "Enter loan amount",
        label2: "Enter email address",
        bannerSectionFlip: "No",
      },
      style: {
        themeColor2: "#00A6BA",
        backgroundColor: "#E8FDFF",
        gradColor1: "#E8FDFF",
        gradColor2: "#FAF6FF ",
        subHeadingColor: "#737E80",
        HeadingColor: "#1F3133",
        HeadingAnimColor: "#00A6BA",
        headertextColor: "#00A6BA",
        fieldHoverBorder: "#00A6BA",
        fieldErrorBorder: "#ff2b2b",
        discLinkColor: "#000",
        ctaBg: "#00A6BA",
        ctaTextColor: "#ffffff",
        ctaHoverBg: "#0085FF",
        ctaHoverTextColor: "#ffffff",
        formHeaderBg: "#ffffff",
        formShadowColor: "#837eff3d",
        inputfocuscolor: "#00A6BA",
      },
    },

    whyUsSection: {
      section: "Why Us",
      content: {
        titleText: "Why Choose <span style='color:#00A6BA'>Us?</span>",
        titleTextTheme: "Us?",
        icon1bold: "Easy & Short",
        icon1text: "Loan form",
        icon2bold: "Minimum",
        icon2text: "Processing Time",
        icon3bold: "Trusted",
        icon3text: "Lending Sources",
        icon4bold: "Simple & Secure",
        icon4text: "Process",
      },
      style: {
        themeColor2: "#00A6BA",
        titleColor: "#1F3133",
        iconColor: "#00A6BA",
        boldTextColor: "#1F3133",
        paraTextColor: "#737E80",
      },
    },

    userSection: {
      section: "User",
      content: {
        headingText:
          "Trusted by <span style='color:#00A6BA'>thousands</span> of users",
        subheadingText:
          "With the help of our <span> user-friendly, rapid</span> and <span>secure platform,</span> we assisted <span>thousands</span> of users with loan requests.",
        ctaText: "Request Now",
        ctaSubText: "It's free",
        userImage: "banner.png",
        userSectionFlip: "No",
      },
      style: {
        headingColor: "#00A6BA",
        ctaBg: "#00A6BA",
        ctaTextColor: "#ffffff",
        ctaHoverBg: "#0085FF",
        ctaHoverTextColor: "#ffffff",
        iconColor: "#00A6BA",
        boldTextColor: "#1F3133",
        paraTextColor: "#737E80",
      },
    },

    requestSection: {
      section: "Request",
      content: {
        headingText:
          "<span style='color:#00A6BA'>Seamlessly</span> request a loan for ",
        subheadingText:
          "We offer a variety of <span> loan reasons </span>to choose from, with a <span> fast </span> and <span> hassle-free </span>online application process. Find the loan that best suits your needs and request it <span> seamlessly </span> in just a <span> few minutes. </span>",
        icon1text: "Debt Consolidation",
        icon2text: "Home Improvement",
        icon3text: "Auto Repair/ Auto purchase",
        icon4text: "Medical/Emergency",
        icon5text: "Moving/Travelling",
        icon6text: "Other loan reason",
        requestSectionFlip: "no",
      },
      style: {
        backgroundColor: "",
        gradColor1: "#E8FDFF",
        gradColor2: "#FAF6FF ",
        itemBoxShadow: "#FAF6FF ",
        headingColor: "#1F3133",
        subHeadingColor: "#737E80",
        iconColor: "#00A6BA",
        boxShadowColor1: "#837eff14",
        boxShadowColor2: "#837eff1a",
      },
    },

    findSection: {
      section: "Find",
      content: {
        headingText:
          "Finding a loan is as simple as <span style='color:#00A6BA'>1-2-3</span>",
        subheadingText:
          "A <span> quick </span> and <span> seamless </span> process, designed to make things easy for you.",
        ctaText: "Request Now",
        ctaSubText: "It's free",
        icon1bold: "You fill form",
        icon1text:
          "Submit your information with our 2 inute loan request form.",
        icon2bold: "We contact lender",
        icon2text:
          "Our advanced system send your information to our lending network in real time.",
        icon3bold: "You get decision",
        icon3text:
          "If your request is accepted by the lender, you will be redirected to lender’s platform for disbursement.",
        findSectionFlip: "No",
      },
      style: {
        headingColor: "#1F3133",
        subHeadingColor: "#737E80",
        ctaBg: "#00A6BA",
        ctaTextColor: "#ffffff",
        ctaHoverBg: "#0085FF",
        ctaHoverTextColor: "#ffffff",
        iconColor: "#00A6BA",
        boldTextColor: "#1F3133",
        paraTextColor: "#737E80",
      },
    },

    faq: {
      section: "Frequently Asked Questions",
      content: {
        titleText:
          "Got a question? <br /> <span style='color:#00A6BA'> Look here</span>",
        FAQSectionFlip: "No",
        titleTextTheme: "Questions",
        faq: [
          {
            id: "0",
            qus: "What are the requirements to submit a loan?",
            ans: "<p>- Should be employed in your current position for at least 90 days</p><p>- Should be older than 18 years of age, should be a US citizen or a permanent resident</p> <p>- Should have a minimum income of $1000 each month after tax deductions</p> <p>- Should have a valid email address, work and home phone number.</p>",
          },
          {
            id: "1",
            qus: "What amount could I get?",
            ans: "You could be eligible to any amount up to 15000. The approved amount will be determined by the lender. Having a working email address,work telephone number and home telephone are some of the usual prerequisites for getting a loans",
          },
          {
            id: "3",
            qus: "How much time does it take?",
            ans: "It could take as few minutes to process the duly filled form. You could get your loans after approval within the next business day.",
          },
          {
            id: "4",
            qus: "What is the cost?",
            ans: "There is no cost to use whiterockcash.com. The fees of the loan will vary depending upon the amount and the lender. The lender will inform you of the fees for the loan which may vary depending on the lender.",
          },
        ],
        CTAbuttonText: "View more",
        arrow_black: "arrow_black.png",
      },
      style: {
        mainHeadingColor: "#1F3133",
        themeColor2: "#00A6BA",
        backgroundColor: "#fff",
        quesbarbackground: "white",
        answerbarbackground: "white",
        titleColor: "#00A6BA",
        quesTextColor: "#1C1C1F",
        paraTextColor: "#1C1C1F",
      },
    },

    ctaSection: {
      section: "CTA",
      content: {
        headingText:
          "Are you ready to request a loan for <span style='color:#00A6BA'>free</span>",
        subheadingText: "Submit your information and receive a quick decision.",
        ctaText: "Request Now",
        ctaSubText: "It's free",
        userImage: "banner.png",
      },
      style: {
        headingColor: "#1F2933",
        ctaBg: "#00A6BA",
        ctaTextColor: "#ffffff",
        ctaHoverBg: "#0085FF",
        ctaHoverTextColor: "#ffffff",
        iconColor: "#00A6BA",
        boldTextColor: "#1F3133",
        paraTextColor: "#737E80",
      },
    },

    footer: {
      section: "Footer",
      content: {
        footerLogo: "logo-f.svg",
        logoParaText:
          "we are dedicated to providing fast and convenient access to  request emergency funds for those in need.",
        footerCTAbarText: "Request a loan in few clicks",
        sslLogo: "ssl_cer.svg",
        safe: "ssl_sec.svg",
        encrypt: "encrypt.png",
        ola: "ola.png",

        bottomParaText:
          "THE OPERATOR OF THIS WEBSITE IS NOT A LENDER, is not a loan broker, and does not make lending decisions on behalf of lenders. This Web Site does not constitute an offer or solicitation to lend. This site will submit the information you provide to a lender who makes short-term cash loans to borrowers who meet its lending criteria. Providing your information on this Website does not guarantee that you will be approved for a short term cash loan. The operator of this Web Site is not an agent, representative or broker of any lender and does not endorse any particular lender or charge you for any service or product. Not all lenders can provide the maximum amount advertised. Cash transfer times may vary between lenders and may depend on your individual financial institution. In some circumstances faxing may be required. This service is not available in all states, and the states serviced by this Web Site may change from time to time and without notice. For details, questions or concerns regarding your short-term cash loan, please contact your lender directly. Short term cash loans are meant to address immediate cash needs and are not a long-term solution for financial problems. Residents of some states may not be eligible for a short term cash loan based upon lender requirements.",
      },
      style: {
        backgroundColor: "#1F3133",
        footerParaColor: "#fff",
        linkColor: "#ffffff99",
        linkColorHover: "#ffffff",
        foterBottomBg: "#ffffff",
        footerBarTextColor: "#1f1f1c",
        bottomParaTextColor: "#1f313399",
        ctaBg: "#00A6BA",
        ctaTextColor: "#ffffff",
        ctaHoverBg: "#0085FF",
        ctaHoverTextColor: "#ffffff",
      },
    },
  },
};
export const HomeContext = createContext();
export const PageContext = createContext();

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [fevIcon, setFevIcon] = useState("");
  const [faviconUrl, setFaviconUrl] = useState("");
  const [homeData, setHomeData] = useState(defaultData);
  const [pageData, setPageData] = useState([]);

  useEffect(() => {
    fetch("data/default_data.json")
      .then((res) => res.json())
      .then((data) => {
        setHomeData(data);
      });
  }, []);

  useEffect(() => {
    fetch("data/website_config.json")
      .then((res) => res.json())
      .then((data) => {
        // setWebname(data);
        const myData = {
          websiteName: data.WEBSITE_NAME,
          webSiteId: data.WEBSITE_ID,
        };
        dataFetchHandler(myData);
        handleApiCall(myData);
      });
  }, []);

  // Data Fetch Hendler
  const dataFetchHandler = async (myData) => {
    setIsLoading(true);
    try {
      const res = await API.post("API/getLandingPageContent", myData);
      if (res.status === 200) {
        setHomeData(res.data.data);
        handleAppTitle(res.data.data.customization.title);
        //  setFevIcon(res.data.data.favicon_img);
        setIsLoading(false);
        TestGA(res.data.data.ga_script);
      
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      return error;
    }
  };

  const handleApiCall = async (myData) => {
    try {
      //  const article = { websiteName: web, webSiteId: websiteId };
      let res = await API.post("Content/getWebsiteContent", myData);
      setPageData(res.data[0]);
    } catch (error) {
      console.error("whySectionerror", error.message);
    }
  };

  // fav
  const Favicon = homeData?.favicon_img;
  useEffect(() => {
    const link =
      document.querySelector("link[rel*='icon']") ||
      document.createElement("link");
    link.type = "image/x-icon";
    link.rel = "shortcut icon";
    link.href = faviconUrl;
    document.getElementsByTagName("head")[0].appendChild(link);
    changeFavicon();
  }, [faviconUrl]);

  // Change the favicon dynamically
  const changeFavicon = () => {
    setFaviconUrl(`static/assets/images/${Favicon}`);
  };
  console.log(fevIcon);
  // title
  const handleAppTitle = (codeTitlr) => {
    document.title = `${codeTitlr}`;
  };

  // Push notifications
  const pushScriptUrl = homeData?.push_script;
  useScript(pushScriptUrl);

  const TestGA = (codeGa) => {
    TagManager.initialize({ gtmId: `${codeGa}` });
  };

  return !isLoading ? (
    <HomeContext.Provider value={homeData}>
      <PageContext.Provider value={pageData}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/form" element={<MainForm />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/how-it-works" element={<HowItWorks />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/privacy-policy" element={<PrivcyPolicy />} />
          <Route path="/terms-of-services" element={<Terms />} />
          <Route path="/e-consent" element={<EConsent />} />
          <Route path="/disclaimer" element={<Disclaimer />} />
          <Route path="/do-not-sell" element={<DoNotSell />} />
          <Route path="/unsubscribe" element={<Unsubscribe />} />
          <Route path="/rate-and-fees" element={<RateAndFees />} />
          <Route path="/faq-page" element={<FaqPage />} />
        </Routes>
      </PageContext.Provider>
    </HomeContext.Provider>
  ) : (
    <div
      id="spinner"
      style={{
        display: "flex",
        justifyContent: "center",
        paddingTop: "150px",
      }}
    >
      <div>{/* <img src={favimg} className="" alt="favicon"/> */}</div>

      <div className="dots">
        <div className="dot dot-1"></div>
        <div className="dot dot-2"></div>
        <div className="dot dot-3"></div>
      </div>
    </div>
  );
}

export default App;
